// extracted by mini-css-extract-plugin
export const sectionSpacing = "orientacion-vocacional-module--section-spacing--2Nua9";
export const rowSpacing = "orientacion-vocacional-module--row-spacing--KQzvW";
export const rowSpacingSml = "orientacion-vocacional-module--row-spacing-sml--1-YoN";
export const infoRow = "orientacion-vocacional-module--infoRow--Mr8-9";
export const infographic = "orientacion-vocacional-module--infographic--G_CUs";
export const infoTitle = "orientacion-vocacional-module--infoTitle--3PQEc";
export const moreInfo = "orientacion-vocacional-module--moreInfo--1mNae";
export const vlog = "orientacion-vocacional-module--vlog--19b2a";
export const videoRow = "orientacion-vocacional-module--videoRow--184wF";
export const postVideoBox = "orientacion-vocacional-module--postVideoBox--1CWAY";
export const videoBox = "orientacion-vocacional-module--videoBox--3ocl1";