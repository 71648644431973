// import dependencies
import React from "react";
import {graphql, Link} from "gatsby";
import Image from "gatsby-plugin-sanity-image";

// import components
import ContactForm from "../components/forms/form-contact";
import Layout from "../components/layout";
import Pagination from "../components/pagination";
import PdfDisplay from "../components/pdf-display";
import PostCard from "../components/cards/card-post";
import Section from "../components/section";
import SectionHeader from "../components/section-header";
import SeparatorTopGrey from "../components/separators/separator-top-grey";
import SeparatorTopYellow from "../components/separators/separator-top-yellow";

// import styles and assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/font-awesome";
import pdfDoc from "../images/glosario.pdf";
import * as styles from "./styles/orientacion-vocacional.module.scss";

export default function NinasOrientacion({data, pageContext}) {
    return(
<Layout>
    <Section color={"grey"}>
        <div className={`container ${styles.infographic}`}>
            <div className={`row ${styles.infoRow}`}>
                <div className={`col-10 offset-1 ${styles.infoTitle}`}>
                    <h1>¿Conoces lo que son las carreras STEM?</h1>
                    <h4>STEM es una sigla en inglés, que se ocupa para referirse a las áreas de Ciencia (Science), Tecnología (Technology), Ingeniería (Engineering) y Matemática (mathematics). De estas disciplinas se desprenden diferentes carreras técnicas y profesionales. Hoy hemos seleccionado algunas de ellas para que las conozcas y puedan ser incluidas en tus posibilidades de educación superior. ¡Porque tú también puedes ser parte de la Ciencia y Tecnología! Te invitamos a revisar este librillo con los conceptos más relevantes de cada carrera. Sigue el código QR y descubre la descripción de cada una según universidades que la imparten.</h4>
                    <h6>Importante: las carreras en Ciencia y tecnología necesariamente entremezclan diversas áreas STEM, por lo que al estudiar alguna ingeniería, por ejemplo, no significa que no integrarás tecnología, matemática o ciencia.</h6>
                </div>
                <div className="col-sm-12 d-flex justify-content-center">
                    <PdfDisplay docFile={pdfDoc} display="tall"/>
                </div>
            </div>
        </div>
    </Section>
    <Section color={"white"}>
        <div className={`container ${styles.moreInfo}`}>
            <div className={`row align-items-center ${styles.infoRow}`}>
                <div className="col-sm-12 col-md-6">
                    <h1>¿Tienes dudas sobre qué carrera STEM seguir?</h1>
                    <h4>Si necesitas orientación sobre cuál es la mejor alternativa para ti en el campo de las STEM, ¡Podemos ayudarte! Nos encantaría poder guiarte en este interesante camino, por lo que te agradeceríamos llenaras el siguiente formulario para ponernos en contacto contigo y ayudarte en tu decisión vocacional.</h4>
                </div>
                <div className="col-sm-12 col-md-6 justify-content-center align-self-center">
                    <ContactForm />
                </div>
            </div>
        </div>          
    </Section>
    <SeparatorTopYellow></SeparatorTopYellow>
    <Section color={"yellow"}>
        <div className={`container ${styles.vlog}`}>
            <SectionHeader color="white">
                <h1>¡Descubre más de las STEM con experiencias reales!</h1>
                <h4>Estudiantes o egresadas de Astronomía, Ingeniería Eléctrica e Ingeniería en Informática te cuentan sus historias y aciertos en sus carreras.</h4>
            </SectionHeader>
            <div className={`row ${styles.videoRow}`}>
                <div className="col-12">
                    <div className={styles.videoBox}>
                        {data.allSanityPost.nodes.map((node) => ( 
                            <PostCard key={node.id}>
                                <div className="photo" key={node.mainImage.asset.id}>
                                    <Image 
                                        {...node.mainImage}
                                        width={320}
                                        height={320}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                        alt="Imágen de Orientación vocacional" />
                                </div>
                                <div className="box">
                                    <h4>{node.title}</h4>
                                    <div className="sep"></div>
                                    <p>{node.excerpt}</p>
                                    <Link to={`/orientacion-vocacional/${node.slug.current}`}><FontAwesomeIcon icon={["fas", "arrow-right"]} /></Link>
                                </div>
                            </PostCard>
                        ))}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Pagination pageContext={pageContext} color="red"/>
                </div>
            </div>
        </div>
        <SeparatorTopGrey />
    </Section>
</Layout>
    );
}

export const query = graphql`
query orientVocPageQuery ($skip: Int!, $limit: Int!) {
    allSanityPost (
    filter: {category: {title: {eq: "Orientación vocacional"}}},
    sort: {order: DESC, fields: publishedAt}
    skip: $skip,
    limit: $limit) {
        nodes {
            id
            title
            slug {
                current
            }
            excerpt
            mainImage {
                ...ImageWithPreview
            }
        }
    }
}
`;