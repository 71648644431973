// import dependencies
import { API } from "aws-amplify";
import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

// import styles and assets
import * as styles from "../styles/form-contact.module.scss";

export default function InfoForm () {

    // ------
    // states
    // ------
    // define initial form state
    const initialFormState = {
        name: "",
        email: "",
        formType: "normal"
    }
    // define form state
    const [formState, updateFormState] = useState(initialFormState);

    // -------------
    // form triggers
    // -------------
    async function changeFormState() {
        updateFormState(() => ({...formState, formType: "emailSent"}));
    }

    // ----------------
    // form validations
    // ----------------

    function validateName(value) {
        let error,
            format = /[^a-zA-Z\s\-/]/;
                
        if (!value) {
            error = "El nombre es requerido";
        } else if (format.test(value)) {
            error = "El nombre no puede tener números y caracteres especiales";
        }
        
        return error;
    }

    function validateEmail(value) {
        let error,
            format = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if (!value) {
            error = "El email es requerido";
        } else if (!format.test(value)) {
            error = "Dirección de email inválida";
        }
        
        return error;
    }

    // setup form state
    const {formType} = formState;

    return(
        <div>
            {
                formType === "normal" && (
                    <Formik
                        initialValues={{
                            name: "",
                            email: "",
                        }}
        
                        onSubmit={async (values, { setSubmitting }) => {
                            const requestInfo = {
                                headers: {
                                },
                                body: {
                                    name: values.name,
                                    email: values.email,
                                    message: values.message
                                }
                            }
                            console.log(requestInfo);
        
                            await API.post("formsapi", "/contact", requestInfo)
                            .then((response) => {
                                console.log("Sent email response:", response);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                            changeFormState();
                                    
                            setSubmitting(false);
                        }}
                    >
                        <Form className={styles.infoForm}>
                            <fieldset>
                                <label htmlFor="name">Nombre</label>
                                <Field id="Name" name="name" validate={validateName}/>
                                <ErrorMessage name="name" component="span" />
                                <label htmlFor="email">Email</label>
                                <Field
                                    id="email"
                                    name="email"
                                    type="email"
                                    validate={validateEmail}
                                />
                                <ErrorMessage name="email" component="span" />
                                <label htmlFor="message">Mensaje</label>
                                <Field
                                    id="message"
                                    name="message"
                                    as="textarea"
                                />
                            </fieldset>
                            <fieldset>
                                <button type="submit">Enviar</button>
                            </fieldset>
                        </Form>
                    </Formik>
                )
            }
            {
                formType === "emailSent" && (
                    <Formik>
                        <Form className={styles.infoForm}>
                            <fieldset>
                                <h4>!Gracias por tu interes!</h4>
                                <p>Te contactaremos a la brevedad para conversar sobre tus dudas.</p>
                            </fieldset>
                        </Form>
                    </Formik>
                )
            }
        </div>
    );
}
